@import "styles/common.scss";
.container {
    border-radius: 16px;
    @include mobile {
        width: calc(100% - 32px);
        padding-bottom: 8px;
    }
    .header {
        @include flex-center;
        padding: 10px 0;

        .title {
            @include flex-center;
            @include f-inter-semi-bold(1.6rem, 2.2rem);
            width: 100%;
            margin-left: 10px;
        }

        .close-icon {
            position: relative;
            right: 10px;
            height: 28px;
            width: 28px;
            padding: 0;
            background-color: var(--ui-teal-light-surface-overlay-silent);
            border-radius: 50%;
            > div {
                margin: auto;
                width: 14px;
                height: 14px;
                color: var(--text-icon-on-lights-secondary);
            }
        }
    }

    .desc {
        @include flex-column-center;
        @include f-inter-regular(1.5rem, 2.4rem);
        @include desktop {
            max-width: 460px;
        }
        gap: 16px;
        align-self: stretch;
        color: var(--text-icon-on-lights-secondary);
        padding: 8px 20px;
    }
    .footer {
        @include flex-center;
        flex-direction: row-reverse;
        padding: 12px 0;
        .buttom-wrapper {
            @include flex-center;
            padding: 0 16px;
            width: 100%;
            gap: 8px;
        }
    }
}
