@import 'styles/common.scss';

.countryName {
  @include f-inter-semi-bold(1.2rem, 1.7rem);
  color: var(--text-icon-on-lights-tertiary);
  padding-top: 28px;
  padding-bottom: 8px;
  ~ .modalCheckboxes {
    row-gap: 0;
  }
}
.counts {
  &Row {
    @include f-inter-regular(1.4rem, 2rem);
    padding: 12px 16px 8px 16px;
  }
  &Button {
    @include f-inter-regular-2(1.4rem, 2rem);
    padding: 0;
  }
}
.spinner {
  @include flex-center;
  margin: 0 auto;
}
.modal {
  &Checkboxes {
    background: var(--ui-teal-light-bg-primary);
    border-radius: 14px;
    flex-direction: column;
    @include desktop {
      padding: 12px;
    }
    &Row {
      flex-direction: column;
      cursor: pointer;
      .separator {
        border: none;
        border-bottom: 1px dashed #ced0d4;
        padding: 0 16px;
        width: 100%;
      }
      &:last-of-type {
        hr {
          display: none;
        }
      }
    }
  }
  &Selectionbox {
    border-radius: 10px;
    padding: 10px 0;
    padding-left: 10px;
    & > input {
      border: none;
    }
  }
}
