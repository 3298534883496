@import 'styles/common.scss';

.remoteButton {
  @include flex(center, space-between);
  @include f-inter-semi-bold(1.6rem, 2.2rem);
  background: var(--ui-teal-light-bg-primary);
  border-radius: 14px;
  margin: 24px 0 8px;
  @include mobile {
    padding: 0;
  }
  @include desktop {
    padding-right: 0;
  }
  &:hover {
    background: var(--ui-teal-light-bg-primary);
  }
}
