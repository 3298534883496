@import "styles/common.scss";

.footer{
    padding: 28px;
    border-top: 1px solid var(--border-on-lights-10);
    background: var(--ui-teal-light-bg-primary);
    position: fixed;
    width: 100%;
    max-width: 900px;
    bottom: 35px;
    border-radius: 0 0 8px 8px;
   .button {
      &Row {
        @include f-inter-semi-bold(1.6rem, 2.2rem);
        color: var(--text-icon-on-lights-secondary);
      }
    }
  }