@import 'styles/common.scss';

.SidebarMP {
    @include mobile {
        // todo check
        height: calc(100vh - 144px);
        overflow: auto;
        padding: 16px;
    }
    .menu-item{
        @include f-inter-regular(1.4rem,2rem);
        padding: 10px 12px;
        border-radius: 6px;
       margin-bottom: 4px;
       &:last-of-type{
        margin: 0;
       }
        &:hover {
            background: var(--ui-teal-light-bg-primary);
            cursor: pointer;
        }
    }
    .menu-item-selected {
        background: var(--ui-teal-light-bg-primary);
        font-weight: 600;
    }
    .icon {
        color: var(--text-icon-tertiary);
    }
    .icon-selected {
        color: var(--primary-color);
    }
    .icon-gap{
        width: 18px;
    }
    .menu-item-mobile{
        @include f-inter-regular-2(1.5rem,2.1rem);
        background: var(--ui-teal-light-bg-primary);
        padding: 12px 16px;
        border-bottom: 1px solid var(--border-on-lights-10);
        &:first-of-type{
            border-radius: 16px 16px 0 0;
        }
        &:last-of-type{
            border-radius: 0 0 16px 16px;
            border: none;
        }
        &:only-child{
            border-radius: 16px;
            border: none;    
        }
    }
    .gap {
        margin-top: 16px;
    }
    .footer{
        background-color: var(--bg-primary-light);
    }
}
