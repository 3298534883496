@import "styles/common.scss";
.footerScrolled {
  border-top: 1px solid var(--border-border-10);
  background: var(--ui-teal-light-bg-secondary);
  box-shadow: 0px -1px 2px 0px var(--black-color-03);
  backdrop-filter: blur(6px);
}
.footer {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left:0;
  width: 100%;
  @include flex-column(center, center);
  height: 80px;
  gap: 10px;

  .wrapper {
    @include flex(center, center);
    @include desktop{
      max-width: 456px;
    }
    @include media-breakpoint-small {
      padding: 0 20px;
    }
    width: 100%;
    padding: 8px 0px;
    gap: 10px;
    
    .nextButton {
      @include flex(center, center);
      @include f-inter-semi-bold(1.6rem, 2.2rem);
      padding: 13px 20px;
      gap: 10px;
      flex: 1;
      &Disabled {
        background-color: var(--surface-transparent-secondary-loud);
        border: none; 
      }
    }
    .hide-mobile{
      @include hide-small()
    }
  }

}
.fullyScrolled {
  border-top-color: transparent;
  box-shadow: none;
}

