@import "styles/common.scss";
.container{
    border-radius: 16px;
    .header {
        @include flex-center;
        padding: 10px 0;
        background-color: var(--ui-teal-light-surface-tertiary);
    
        .title {
            @include flex-center;
            @include f-inter-semi-bold(1.6rem, 2.2rem);
            width: 100%;
            margin-left: 10px;
        }
    
        .close-icon {
            position: relative;
            right: 10px;
            height: 28px;
            width: 28px;
        }
    }
    
    .desc {
        @include flex-column-center;
        @include f-inter-regular(1.5rem,2.4rem);
        @include desktop {
            max-width: 460px;
        }
        background-color: var(--ui-teal-light-surface-tertiary);
        padding: 12px 28px 32px 28px;
        gap: 16px;
        align-self: stretch;
        color: var(--text-icon-on-lights-secondary);
    }
    .footer {
        @include flex(center);
        flex-direction: row-reverse;
        padding: 28px;
        .button {
            text-transform: uppercase;
            min-width: 78px;
        }
    }
}

