@import 'styles/common.scss';
$zindex-dropdown: 999;
.MultiSelect {
  position: relative;
  display: inline-flex;
  white-space: nowrap;
  &Trigger {
    @include ui-text-ui-14-semibold;
    @include flex(center);
    column-gap: 8px;
    border: 0;
    background: var(--bg-primary);
    color: var(--text-secondary);
    border-radius: 10px;
    padding: 9px 16px;
    border: 1px solid transparent;
    cursor: pointer;
    @include small {
      padding: 4px 8px;
    }
    &:disabled {
      color: var(--text-tertiary);
    }
    &.apply-on-show {
      background-color: var(--surface-overlay-silent);
      color: var(--text-primary-v2);
      &.selected {
        background-color: var(--primary-color);
        color: var(--text-icon-text-icon-inverted-primary);
      }
    }
    &FullWidth {
      width: 100%;
      p {
        width: 100%;
      }
    }
    &HasBorder {
      border: 1px solid var(--border-color);
      @include sm-desktop-up {
        border: none;
      }
    }
  }
  &SelectedCount {
    span {
      @include fns-bold(1.2rem, 1.9rem);
      padding: 2px 8px;
      background: var(--primary-color);
      color: var(--accent-text);
      margin-left: 8px;
      border-radius: 25%;
      @include small {
        padding: 2px 6px;
        margin-left: 4px;
      }
    }
  }
  &Icon {
    &Reverse {
      transform: rotateX(180deg);
    }
    &,
    &Reverse {
      transition: transform 0.2s;
    }
  }
  &Dropdown {
    @include flex-column;
    position: absolute;
    width: auto;
    background: var(--bg-primary);
    border-radius: 10px;
    box-shadow: 0px 0px 10px var(--shadow-color);
    z-index: $zindex-dropdown;
    min-width: 272px;
    @include small {
      max-width: 320px;
    }
    &Align {
      &Left {
        left: 0;
      }
      &Right {
        right: 0;
      }
      &Center {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &Search {
      padding: 14px 14px 4px 14px;
      .MultiSelectDropdownSearchInput {
        color: var(--text-primary);
        input {
          @include ui-text-ui-16-regular;
          background-color: var(--ui-teal-light-surface-overlay-silent);
          padding: 7px 12px 7px 33px;
          border: none;
          &::placeholder {
            @include ui-text-ui-16-regular;
            color: var(--text-icon-placeholder);
          }
        }
      }
      .MultiSelectDropdownSearchIcon {
        left: 4px;
        color: var(--text-icon-placeholder);
      }
    }
    &Options {
      padding: 8px 4px 4px;
      height: auto;
      flex: 1;
      max-height: 292px;
      overflow: auto;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: var(--scroll-bar-color);
        box-shadow: 0 0 1px rgba($color: $black, $alpha: 0.5);
      }
      li {
        @include flex(center);
        color: var(--text-primary);
        padding: 7px 4px 7px 10px;
        width: 100%;
        border-radius: 10px;
        cursor: default;
        @include small {
          padding-right: 0;
        }
        &:hover {
          background: var(--bg-secondary);
        }
      }
    }
    &Option {
      &Selected {
        &:not(.MultiSelectDropdownOptionLabelFirst) {
          background: var(--bg-secondary);
        }
      }
      &CheckboxDisabled {
        input {
          opacity: 40%;
        }
        label {
          opacity: 40%;
        }
      }
      &Checkbox {
        width: 100%;
        label {
          @include fns-regular(1.4rem, 1.6rem);
          color: var(--text-primary);
          width: 100%;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:disabled {
            color: red;
          }
        }
        img {
          margin: 0 6px 0 2px;
          height: 18px;
          width: auto;
        }
      }
    }
    &NoResults {
      @include fns-regular(1.6rem, 1.9rem);
      text-align: center;
      padding: 6px 14px 20px;
    }
    &Footer {
      @include flex(center, flex-end);
      padding: 12px 24px;
      border-top: 0.5px solid var(--border-color);
    }
    &Top {
      bottom: calc(100% + 10px);
    }
    &Bottom {
      top: calc(100% + 10px);
    }
    &FullWidth {
      width: 100%;
    }
  }
  &Spinner {
    height: 200px;
    @include flex-center;
  }
  .valuesEllipsis {
    @include multi-line-ellipsis(1);
    text-align: left;
  }
  &FullWidth {
    width: 100%;
  }
  .actions {
    @include flex(center, flex-end);
    column-gap: 8px;
    padding: 12px 14px;
    border-top: 1px solid var(--border-border-10);
    border-radius: 0 0 10px 10px;

    .show-button {
      min-width: 100px;
    }
  }
}

.MultiSelectDropdownSearch ~ .MultiSelectDropdownOptions {
  padding: 4px;
}

.spinner {
  @include flex-center;
  padding: 4px 0 12px;
}
